import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from '@mui/material';
import {
  Brush as BrushIcon,
  ColorLens as ColorLensIcon,
  FitnessCenter as FitnessCenterIcon,
  Flag as FlagIcon,
} from '@mui/icons-material';

import {
  CheckoutButton,
  ProductFeatureList,
  Testimonials,
} from '../components';
import { ProductImageUrl } from '../consts';

export const HomePage = () => {
  const features = [
    {
      name: 'Durable Material',
      description: 'Made with high-quality, sustainable materials designed to last a lifetime.',
      icon: FitnessCenterIcon,
    },
    {
      name: 'Unique Design',
      description: 'Made with high-quality, sustainable materials designed to last a lifetime.',
      icon: BrushIcon,
    },
    {
      name: 'Custom Colors',
      description: 'Made with high-quality, sustainable materials designed to last a lifetime.',
      icon: ColorLensIcon,
    },
    {
      name: 'Made in the United State of America',
      description: 'Made with high-quality, sustainable materials designed to last a lifetime.',
      icon: FlagIcon,
    },
  ];
  return (
    <>
      <Container
        maxWidth="md"
        component="main"
        sx={{ pt: 3, pb: 6 }}
      >
        <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
          The Bill Bender
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          The ultimate tool for hat enthusiasts. Shape your hat exactly the way you
          want with precision and ease.
        </Typography>
        <Box mt={4} display="flex" justifyContent="center">
          <CheckoutButton />
        </Box>
      </Container>
      <Container
        maxWidth="md"
        component="section"
      >
        <Card>
          <CardMedia
            component="img"
            image={ProductImageUrl}
            alt="Bill bender image"
            width="75%"
            height="auto"
          />
          <CardContent>
            <ProductFeatureList features={features} />
          </CardContent>
        </Card>
      </Container>
      <Container
        maxWidth="md"
        component="section"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 3],
        }}
      >
        <Testimonials />
      </Container>
    </>
  );
};