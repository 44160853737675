import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  ArrayField,
  AutocompleteInput,
  ChipField,
  Count,
  DatagridConfigurable,
  DateField,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceInput,
  SearchInput,
  SelectColumnsButton,
  SingleFieldList,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import {
  Divider,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {
  MobileGrid,
  NbItemsField,
  UserReferenceField,
} from '..';
import { UserModel } from '../../../types';
import { getOrderStatusColor, getOrderStatusName } from '../../../modules';

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

export const OrderList = () => (
  <List
    filterDefaultValues={{ status: 'pending' }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    filters={orderFilters}
    actions={<ListActions />}
  >
    <TabbedDatagrid />
  </List>
);

const orderFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="id" reference="users">
    <AutocompleteInput
      optionText={(choice?: UserModel) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.username}`
          : ''
      }
      sx={{ minWidth: 200 }}
    />
  </ReferenceInput>,
  <TextInput source="username" />,
  <NullableBooleanInput source="returned" />,
];

const tabs = [{
  id: 'pending',
  name: 'Orders',
  filter: [
    'pending', 'paid', 'processing',
    'shipped', 'delivered',
  ],
}, {
  id: 'returned',
  name: 'Returns',
  filter: [
    'returned',
    'awaiting_return',
    'processing_return',
  ],
}, {
  id: 'canceled',
  name: 'Canceled',
  filter: ['canceled'],
}];

const TabbedDatagrid = () => {
  const listContext = useListContext();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { filterValues, setFilters, displayedFilters } = listContext;
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const hasReturnStatus = ['returned', 'awaiting_return', 'processing_return'].includes(filterValues.status);
  const hasCanceledStatus = filterValues.status === 'canceled';

  const handleTabSelection = useCallback((index: any) => {
    setSelectedIndex(index);
    setFilters &&
      setFilters(
        {...filterValues, status: tabs[index].filter},
        displayedFilters,
        false, // no debounce, we want the filter to fire immediately,
      );
  }, [displayedFilters, filterValues, setFilters]);

  const handleChange = (event: any, value: any) => handleTabSelection(value);

  useEffect(() => {
    if (selectedIndex <= -1) {
      handleTabSelection(0);
    }
  }, [selectedIndex, listContext, handleTabSelection]);

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={selectedIndex > -1 ? selectedIndex : 0}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice, index) => (
          <Tab
            key={index}
            label={
              <span>
                {choice.name} (
                <Count
                  filter={{
                    ...filterValues,
                    status: choice.filter,
                  }}
                  sx={{ lineHeight: 'inherit' }}
                />
                )
              </span>
            }
            value={index}
          />
        ))}
      </Tabs>
      <Divider />
      {isXSmall ? (
        <MobileGrid />
      ) : (
        <>
          {!hasReturnStatus && !hasCanceledStatus && (
            <DatagridConfigurable
              rowClick="edit"
              //omit={['total_ex_taxes', 'delivery_fees', 'taxes']}
            >
              <DateField source="createdAt" label="Date" /*showTime*/ />
              <UserReferenceField source="userId" label="Customer" />
              <ArrayField label="Products" source="products">
                <SingleFieldList resource="products">
                  <ChipField size="small" source="name" />
                </SingleFieldList>
              </ArrayField>
              <NbItemsField />
              <NumberField
                source="amount"
                options={{
                  style: 'currency',
                  currency: 'USD',
                }}
                sx={{ fontWeight: 'bold' }}
              />
              <FunctionField
                label="Status"
                render={(record: any) => (
                  <Typography
                    variant="inherit"
                    style={{color: getOrderStatusColor(record.status)}}
                  >
                    {getOrderStatusName(record.status)}
                  </Typography>
                )}
              />
            </DatagridConfigurable>
          )}
          {hasReturnStatus && (
            <DatagridConfigurable
              rowClick="edit"
              //omit={['total_ex_taxes', 'delivery_fees', 'taxes']}
            >
              <DateField source="createdAt" label="Date" /*showTime*/ />
              <UserReferenceField source="userId" label="Customer" />
              <ArrayField label="Products" source="products">
                <SingleFieldList resource="products">
                  <ChipField size="small" source="name" />
                </SingleFieldList>
              </ArrayField>
              <NbItemsField />
              <NumberField
                source="amount"
                options={{
                  style: 'currency',
                  currency: 'USD',
                }}
                sx={{ fontWeight: 'bold' }}
              />
              <FunctionField
                label="Status"
                render={(record: any) => (
                  <Typography
                    variant="inherit"
                    style={{color: getOrderStatusColor(record.status)}}
                  >
                    {getOrderStatusName(record.status)}
                  </Typography>
                )}
              />
            </DatagridConfigurable>
          )}
          {hasCanceledStatus && (
            <DatagridConfigurable
              rowClick="edit"
              //omit={['total_ex_taxes', 'delivery_fees', 'taxes']}
            >
              <DateField source="createdAt" label="Date" /*showTime*/ />
              <UserReferenceField source="userId" label="Customer" />
              <ArrayField label="Products" source="products">
                <SingleFieldList resource="products">
                  <ChipField size="small" source="name" />
                </SingleFieldList>
              </ArrayField>
              <NbItemsField />
              <NumberField
                source="amount"
                options={{
                  style: 'currency',
                  currency: 'USD',
                }}
                sx={{ fontWeight: 'bold' }}
              />
              <FunctionField
                label="Status"
                render={(record: any) => (
                  <Typography
                    variant="inherit"
                    style={{color: getOrderStatusColor(record.status)}}
                  >
                    {getOrderStatusName(record.status)}
                  </Typography>
                )}
              />
            </DatagridConfigurable>
          )}
        </>
      )}
    </Fragment>
  );
};