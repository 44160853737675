import {
  Container,
  Typography,
} from '@mui/material';

export const TermsOfServicePage = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Terms of Service Agreement
      </Typography>
      <Typography paragraph component="p">
        Terms of Service agreement terms go here.
      </Typography>
    </Container>
  );
};