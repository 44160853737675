import React, { MouseEvent, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  ArrowDropDown as ArrowDropDownIcon,
  GridView as GridViewIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';

import { ActiveMenuItemColor, CreatePortalSessionUrl, Routes, StorageKeys } from '../consts';
import { useColorMode } from '../hooks';
import { get } from '../modules';
import { AuthService } from '../services';
import { getUserToken } from '../stores';

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { mode } = useColorMode();
  const currentUser = getUserToken();
  const open = Boolean(anchorEl);
  const isAuthenticated = Boolean(get(StorageKeys.IsAuthenticated));

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <Tooltip title="Account Settings">
        <IconButton
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          size="small"
          sx={{color: 'inherit'}}
          onClick={handleClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                color: 'inherit',
                bgcolor: mode === 'light' ? '#303030' : ActiveMenuItemColor, //'#909090',
                width: 28,
                height: 28,
              }}
            />
            <ArrowDropDownIcon sx={{ fontSize: '1rem', ml: 0 }} />
          </Box>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isAuthenticated ? (
          <AuthenticatedDropdownItems
            customerId={currentUser?.id}
            onClose={handleClose}
          />
        ) : (
          <NotAuthenticatedDropdownItems
            onClose={handleClose}
          />
        )}
      </Menu>
    </React.Fragment>
  );
};

const AuthenticatedDropdownItems = (props: any) => {
  const { customerId, onClose } = props;

  const handleCustomerPortal = async () => {
    // Call the backend to create the portal session
    const response = await fetch(CreatePortalSessionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerId }),
    });
    const { url } = await response.json();
    //console.log('portalSession:', portalSession);
  
    // Redirect to the Customer Portal
    window.location.href = url;
  };

  const handleMyOrders = () => {
    onClose();
    window.location.href = Routes.account.orders;
  };

  const handleMySettings = () => {
    onClose();
    window.location.href = Routes.account.settings;
  };

  const handleLogout = () => {
    // TODO: enqueueSnackbar('Successfully logged out!', { variant: 'success' });
    onClose();
    AuthService.logout();
  };

  return (
    <>
      <Tooltip title="Customer Portal" placement="left">
        <MenuItem
          onClick={handleCustomerPortal}
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <ListItemIcon>
            <GridViewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Customer Portal" />
        </MenuItem>
      </Tooltip>
      <Divider />
      <Tooltip title="My Orders" placement="left">
        <MenuItem
          onClick={handleMyOrders}
          style={{
            textDecoration: 'none',
            color: Routes.account.orders === window.location.pathname ? ActiveMenuItemColor : 'inherit',
          }}
        >
          <ListItemIcon>
            <ShoppingCartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </MenuItem>
      </Tooltip>
      <Tooltip title="My Settings" placement="left">
        <MenuItem
          onClick={handleMySettings}
          style={{
            textDecoration: 'none',
            color: Routes.account.settings === window.location.pathname ? ActiveMenuItemColor : 'inherit',
          }}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </MenuItem>
      </Tooltip>
      <Divider />
      <Tooltip title="Logout" placement="left">
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Tooltip>
    </>
  );
};

const NotAuthenticatedDropdownItems = (props: any) => {
  const { onClose } = props;
  const isAuthenticated = Boolean(get(StorageKeys.IsAuthenticated));

  const handleLogin = () => {
    onClose();
    window.location.href = Routes.login;
  };

  const handleRegister = () => {
    onClose();
    window.location.href = Routes.register;
  };

  return (
    <>
      <MenuItem
        dense
        disabled
        key="not signed in"
        style={{
          textAlign: 'center',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <ListItemText primary={isAuthenticated ? 'versx' : "Not signed in"} />
      </MenuItem>
      <Divider />
      <Tooltip title="Login to your account">
        <MenuItem
          onClick={handleLogin}
          style={{
            textDecoration: 'none',
            color: Routes.login === window.location.pathname ? ActiveMenuItemColor : 'inherit',
          }}
        >
          <ListItemIcon>
            <ShoppingCartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </MenuItem>
      </Tooltip>
      <Tooltip title="Register a new account">
        <MenuItem
          onClick={handleRegister}
          style={{
            textDecoration: 'none',
            color: Routes.register === window.location.pathname ? ActiveMenuItemColor : 'inherit',
          }}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Register" />
        </MenuItem>
      </Tooltip>
    </>
  );
};