import {
  PropTypes,
  Typography,
} from '@mui/material';

import { AppLogo } from '../..';
import { Routes } from '../../../consts';

interface AppBrandProps {
  title: string;
  textColor: PropTypes.Color;
};

export const AppBrand = (props: AppBrandProps) => {
  const { title, textColor = 'inherit' } = props;
  return (
    <>
      <AppLogo
        src="/logo192.png"
        style={{marginRight: 15}}
      />
      &nbsp;
      <Typography
        noWrap
        variant="h6"
        color={textColor}
        component="div"
        sx={{marginRight: 3}}
      >
        <a
          href={Routes.home}
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          {title}
        </a>
      </Typography>
    </>
  )
};