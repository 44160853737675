import { AttachMoney as DollarIcon } from '@mui/icons-material';

import { CardWithIcon } from '.';

export const MonthlyRevenue = ({ value }: any) => (
  <CardWithIcon
    to="/commands"
    icon={DollarIcon}
    title="Monthly Revenue"
    subtitle={value}
  />
);