import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';

import { BreadcrumbItem, Breadcrumbs, QuantitySelector } from '../components';
import { ProductImageUrl, Routes } from '../consts';
import { useShoppingCart } from '../hooks';
import { ProductService } from '../services';
import { ProductModel } from '../types';

export const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState<ProductModel>();

  const { items, addToCart, setQuantity } = useShoppingCart();

  const [showQuantitySelector, setShowQuantitySelector] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const crumbs: BreadcrumbItem[] = [{
    text: 'Home',
    href: Routes.home,
    selected: false,
  },{
    text: 'Products',
    href: Routes.products,
    selected: false,
  },{
    text: product?.name!,
    href: Routes.product,
    selected: true,
  }];

  const handleAddToCartClick = () => {
    setShowQuantitySelector(true);
    handleAddToCart(1);
  };

  const handleAddToCart = (quantity: number) => {
    // Clear any existing timeout to reset the timer
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    // Add the product to the cart logic...
    const item = items.find((item) => parseInt(item.id.toString()) === parseInt(id!));
    if (item) {
      setQuantity(item.id, item.quantity + 1);
    } else {
      addToCart({
        id: product?.id!,
        name: product?.name!,
        description: product?.description!,
        price: product?.price!,
        priceId: product?.priceId!,
        color: 'Black',
        quantity: 1,
      });
    }
  
    // Set a new timeout to hide the quantity selector after 3 seconds
    const quantityTimeoutId = setTimeout(() => {
      setShowQuantitySelector(false);
      setTimeoutId(null); // Clear the timeout ID when it's complete
    }, 3000);
  
    // Store the timeout ID in state
    setTimeoutId(quantityTimeoutId);
  };

  useEffect(() => {
    ProductService.getProduct(parseInt(id!)).then((response: any) => {
      if (response.status !== 'ok') {
        // TODO: Error
        console.error('error:', response.error);
        return;
      }
      setProduct(response.data);
    });
  }, [id]);

  return (
    <Container>
      <Breadcrumbs crumbs={crumbs} />
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: '100%',
                height: 300,
                backgroundColor: 'grey.300',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={ProductImageUrl}
                alt={product?.name}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4">{product?.name}</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {product?.category}
            </Typography>
            <Typography variant="body1" paragraph>
              {product?.description}
            </Typography>
            <Typography variant="h6">${product?.price}</Typography>
            <Box mt={2}>
              {!showQuantitySelector ? (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleAddToCartClick}
                >
                  <ShoppingCartIcon />&nbsp;Add to Cart
                </Button>
              ) : (
                <QuantitySelector onQuantityChange={handleAddToCart} />
              )}
              {/*
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<ShoppingCartIcon />}
                onClick={handleAddToCart}
              >
                Add to Cart
              </Button>
              */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};