import { DataProvider } from 'react-admin';
import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';

import config from '../config.json';

const httpClient = fetchUtils.fetchJson;
const apiUrl = config.apiUrl.endsWith('/')
  ? config.apiUrl.substr(0, config.apiUrl.length - 1)
  : config.apiUrl;

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList      => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne       => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany      => GET http://my.api.url/posts?id=123&id=456&id=789
 * create       => POST http://my.api.url/posts/123
 * update       => PUT http://my.api.url/posts/123
 * updateMany     => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete       => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *   <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *     <Resource name="posts" list={PostList} />
 *   </Admin>
 * );
 *
 * export default App;
 */

export const JsonServerProvider: DataProvider = {
  getList: async (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    //total: parseInt((headers.get('content-range') || '0').split('/').pop() || '0', 10),
    const { json } = await httpClient(url);
    return json;
  },

  getOne: async (resource: any, params: any) => {
    const url = `${apiUrl}/${resource}?id=${params.id}`;
    const { json } = await httpClient(url);
    return json;
  },

  getMany: async (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return json;
  },

  getManyReference: async (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const result = await httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt((headers.get('content-range') || '0').split('/').pop() || '0', 10),
    }));
    return result;
  },

  update: (resource: any, params: any) => httpClient(`${apiUrl}/${resource}/${params.id}`, {
    method: 'PUT',
    body: JSON.stringify(params.data),
  }).then(({ json }: any) => ({ status: 'ok', data: json })),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource: any, params: any) => Promise.all(
    params.ids.map((id: number) =>
      httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      })
    )
  ).then(responses => ({ status: 'ok', data: responses.map(({ json }) => json.id) })),

  create: (resource: any, params: any) => httpClient(`${apiUrl}/${resource}`, {
    method: 'POST',
    body: JSON.stringify(params.data),
  }).then(({ json }) => ({
    data: { ...params.data, status: 'ok', id: json.id } as any,
  })),

  delete: (resource: any, params: any) => httpClient(`${apiUrl}/${resource}/${params.id}`, {
    method: 'DELETE',
  }).then(({ json }: any) => ({ status: 'ok', data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource: any, params: any) => Promise.all(
    params.ids.map((id: number) => httpClient(`${apiUrl}/${resource}/${id}`, {
      method: 'DELETE',
    }))
  ).then(responses => ({ status: 'ok', data: responses.map(({ json }) => json.id) })),
};