import { Theme, Typography, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  BulkDeleteWithConfirmButton,
  Datagrid,
  FunctionField,
  List,
  NumberField,
  SearchInput,
  SimpleList,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import memoize from 'lodash/memoize';

import {
  ProductAside,
  ProductAvatarField,
  ProductEditEmbedded,
} from '..';

export const ProductList = () => {
  const { permissions } = usePermissions();

  const rowClick = memoize((permissions: any) => () => permissions === 'admin'
    ? Promise.resolve('edit')
    : Promise.resolve('show')
  );

  const getProductFilters = (permissions: any): any => [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="name" />,
    permissions === 'admin' ? <TextInput source="role" /> : null,
  ].filter(filter => filter !== null);

  const ProductBulkActionButtons = (props: any) => (
    <BulkDeleteWithConfirmButton {...props} />
  );

  return (
    <List
      filters={getProductFilters(permissions)}
      filterDefaultValues={{ role: 'user' }}
      sort={{ field: 'name', order: 'ASC' }}
      aside={<ProductAside />}
    >
      {useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record =>
            permissions === 'admin' ? record.role : null
          }
        />
      ) : (
        <Datagrid
          rowClick={rowClick(permissions)}
          expand={<ProductEditEmbedded />}
          bulkActionButtons={<ProductBulkActionButtons />}
          optimized
        >
          {/*<NumberField source="id" />*/}
          <FunctionField
            label="Name"
            render={(record: any) => (
              <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
                whiteSpace="nowrap"
              >
                <ProductAvatarField
                  sx={{
                    mr: 1,
                    mt: -0.5,
                    mb: -0.5,
                  }}
                />
                {record.name}
              </Typography>
            )}
          />
          <TextField source="category" />
          {/*<TextField source="description" />*/}
          <NumberField source="quantity" />
          <NumberField source="price" />
          <BooleanField source="enabled" />
        </Datagrid>
      )}
    </List>
  );
};