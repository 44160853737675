import {
  BooleanInput,
  DateField,
  Edit,
  Form,
  FunctionField,
  Labeled,
  PrevNextButtons,
  ReferenceField,
  SelectInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

import { Basket, Totals } from '.';
import { Routes } from '../../../consts';
import { OrderModel, UserModel } from '../../../types';

export const OrderEdit = () => (
  <Edit title={<OrderTitle />} component="div">
    <OrderForm />
  </Edit>
);

const OrderTitle = () => {
  const record = useRecordContext<OrderModel>();
  return record ? (
    <span>{record.id}</span>
  ) : null;
};

const CustomerDetails = () => {
  const record = useRecordContext<UserModel>();
  return (
    <div>
      <Typography
        component={RouterLink}
        color="primary"
        to={`${Routes.admin.users}/${record?.id}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.username}
      </Typography>
      <br />
      {/*
      <Typography
        component={Link}
        color="primary"
        href={`mailto:${record?.email}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.email}
      </Typography>
      */}
    </div>
  );
};

const CustomerAddress = () => {
  const record = useRecordContext<UserModel>();
  return (
    <div>
      <Typography>
        {record?.username}
      </Typography>
      {/*
      <Typography>{record?.address}</Typography>
      <Typography>
        {record?.city}, {record?.stateAbbr} {record?.zipcode}
      </Typography>
      */}
    </div>
  );
};

const Spacer = () => <Box mb={1}>&nbsp;</Box>;

const OrderForm = () => {
  return (
    <Form>
      <Box maxWidth="50em">
        <PrevNextButtons
          filterDefaultValues={{ status: 'pending' }}
          sort={{ field: 'createdAt', order: 'DESC' }}
        />
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  Order
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled label="Created">
                      <DateField source="createdAt" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FunctionField
                      label="Invoice"
                      render={(record: OrderModel) => (
                        <a
                          href={record.invoiceUrl!}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: 'pointer',
                          }}
                        >
                          {record.invoiceId}
                        </a>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <SelectInput
                      source="status"
                      choices={[
                        //{
                        //  id: 'ordered',
                        //  name: 'Orders',
                        //},
                        {
                          id: 'pending',
                          name: 'Pending',
                        },
                        {
                          id: 'paid',
                          name: 'Processing',
                        },
                        {
                          id: 'shipped',
                          name: 'Shipped',
                        },
                        {
                          id: 'delivered',
                          name: 'Delivered',
                        },
                        {
                          id: 'refunded',
                          name: 'Refunded',
                        },
                        {
                          id: 'returned',
                          name: 'Returned',
                        },
                        {
                          id: 'awaiting_return',
                          name: 'Awaiting Return',
                        },
                        {
                          id: 'processing_return',
                          name: 'Processing Return',
                        },
                        {
                          id: 'canceled',
                          name: 'Canceled',
                        },
                        {
                          id: 'unknown',
                          name: 'Unknown',
                          disabled: true,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box mt={2}>
                      <BooleanInput
                        row={true}
                        source="returned"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  Customer
                </Typography>
                <ReferenceField
                  source="userId"
                  reference="users"
                  link={false}
                >
                  <CustomerDetails />
                </ReferenceField>
                <Spacer />

                <Typography variant="h6" gutterBottom>
                  Shipping Address
                </Typography>
                <ReferenceField
                  source="userId"
                  reference="users"
                  link={false}
                >
                  <CustomerAddress />
                </ReferenceField>
              </Grid>
            </Grid>
            <Spacer />

            <Typography variant="h6" gutterBottom>
              Items
            </Typography>
            <div>
              <Basket />
            </div>
            <Spacer />

            <Typography variant="h6" gutterBottom>
              Total
            </Typography>
            <div>
              <Totals />
            </div>
          </CardContent>
          <Toolbar />
        </Card>
      </Box>
    </Form>
  );
};