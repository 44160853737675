import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { OrdersEmail, Routes } from '../consts';
import { useShoppingCart } from '../hooks';
import { CheckoutService } from '../services';
import { getUserToken, setUserToken } from '../stores';

export const OrderResponsePage = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const { clearCart } = useShoppingCart();
  const currentUser = getUserToken();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    if (!sessionId) {
      return;
    }

    CheckoutService.getStatus(sessionId).then((response: any) => {
      if (response.status !== 'succeeded') {
        return;
      }
      setUserToken({
        ...currentUser,
        id: response.customerId,
        name: response.name,
        email: response.email,
      });
      setStatus(response.status);
      setCustomerEmail(response.email);
      if (response.status === 'succeeded') {
        clearCart();
      }
    });
  }, [currentUser, clearCart]);

  switch ((status ?? '')?.toString()) {
    case 'open':
      return (
        <Navigate to={Routes.home} />
      );
    case 'complete':
      return (
        <section id="success">
          <p>
            We appreciate your business! A confirmation email will be sent to {customerEmail}.
  
            If you have any questions, please email <a href={`mailto:${OrdersEmail}`}>{OrdersEmail}</a>.
          </p>
        </section>
      );
    default:
      return null;
  }
};