import {
  Container,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';

import { ProductFeature } from '../../types';

export const ProductFeatureListItem = (feature: ProductFeature) => (
  <ListItem disablePadding>
    <Container
      component={Paper}
      elevation={2}
      style={{padding: 0, marginBottom: 10}}
    >
      <ListItemButton>
        <ListItemIcon><feature.icon /></ListItemIcon>
        <ListItemText
          primary={feature.name}
          secondary={feature.description}
        />
      </ListItemButton>
    </Container>
  </ListItem>
);