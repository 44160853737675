import {
  Edit, Identifier,
  required, SimpleForm, TextInput,
} from 'react-admin';

export const ProductEditEmbedded = ({ id }: { id?: Identifier }) => (
  /* Passing " " as title disables the custom title */
  <Edit title=" " id={id}>
    <SimpleForm defaultValues={{ role: 'user' }}>
      <TextInput
        source="name"
        defaultValue="slim shady"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

/*
UserEditEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};
*/