import { FunctionField } from 'react-admin';

import { OrderModel } from '../../../types';

const render = (record: OrderModel) => record.products?.length!;

export const NbItemsField = () => <FunctionField<any> render={render} />; // OrderModel

NbItemsField.defaultProps = {
  label: 'Quantity',
  textAlign: 'right',
};