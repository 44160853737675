import { Card, CardHeader, List } from '@mui/material';

import { PendingOrder } from '.';
import { OrderModel } from '../../../types';

interface PendingOrdersProps {
  orders?: OrderModel[];
};

export const PendingOrders = (props: PendingOrdersProps) => {
  const { orders = [] } = props;

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader title="Pending Orders" />
      <List dense={true}>
        {orders.map(record => (
          <PendingOrder key={record.id} order={record} />
        ))}
      </List>
    </Card>
  );
};

export default PendingOrders;