import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link, useGetMany, useRecordContext } from 'react-admin';

import { TableCellRight } from '.';
import { ProductImageUrl, Routes } from '../../../consts';
import { OrderModel, ProductModel } from '../../../types';

export const Basket = () => {
  const record = useRecordContext<OrderModel>();
  const productIds = record ? record.products?.map(item => item.id) : [];

  const { isLoading, data: products } = useGetMany<ProductModel | any>(
    'products',
    { ids: productIds as any },
    { enabled: !!record }
  );
  const productsById = products
    ? products.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {} as any)
    : {};

  if (isLoading || !record || !products) {
    return null;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Name
          </TableCell>
          <TableCellRight>
            Unit Price
          </TableCellRight>
          <TableCellRight>
            Quantity
          </TableCellRight>
          <TableCellRight>
            Total
          </TableCellRight>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.products?.map((item: ProductModel) => (
          <TableRow key={item.id}>
            <TableCell style={{display: 'flex', alignItems: 'center'}}>
              <Box
                component="img"
                src={ProductImageUrl}
                alt={item.name}
                sx={{
                  width: '40px',
                  height: 'auto',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
              &nbsp;
              <Link to={Routes.products.replace(':id', item?.id + '')} underline='none' reloadDocument>
                {productsById[item?.id!].name}
              </Link>
            </TableCell>
            <TableCellRight>
              {productsById[item?.id!].price.toLocaleString(
                undefined,
                {
                  style: 'currency',
                  currency: 'USD',
                }
              )}
            </TableCellRight>
            <TableCellRight>{item.productOrder?.quantity!}</TableCellRight>
            <TableCellRight>
              {(
                productsById[item?.id!].price *
                item.quantity
              ).toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
              })}
            </TableCellRight>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};