import {
  Box, Button,
  Card, CardActions,
  Typography,
} from '@mui/material';
import { Code as CodeIcon } from '@mui/icons-material';

export const Welcome = () => (
  <Card
    sx={{
      background: theme =>
        `linear-gradient(45deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.light} 50%, ${theme.palette.primary.dark} 100%)`,
      color: theme => theme.palette.primary.contrastText,
      padding: '20px',
      marginTop: 2,
      marginBottom: '1em',
    }}
  >
    <Box display="flex">
      <Box flex="1">
        <Typography variant="h5" component="h2" gutterBottom>
          Welcome to the react-admin e-commerce demo
        </Typography>
        <Box maxWidth="40em">
          <Typography variant="body1" component="p" gutterBottom>
            This is the admin of an imaginary poster shop. Feel free to
            explore and modify the data - it's local to your computer,
            and will reset each time you reload.
          </Typography>
        </Box>
        <CardActions
          sx={{
            padding: { xs: 0, xl: null },
            flexWrap: { xs: 'wrap', xl: null },
            '& a': {
              marginTop: { xs: '1em', xl: null },
              marginLeft: { xs: '0!important', xl: null },
              marginRight: { xs: '1em', xl: null },
            },
          }}
        >
          <Button
            variant="contained"
            href="https://github.com/marmelab/react-admin/tree/master/examples/demo"
            startIcon={<CodeIcon />}
          >
            Source Code
          </Button>
        </CardActions>
      </Box>
      <Box
        display={{ xs: 'none', sm: 'none', md: 'block' }}
        sx={{
          background: `url(https://raw.githubusercontent.com/marmelab/react-admin/72a452cd8d3ed8ada58173408b1c8a043daf22a2/examples/demo/src/dashboard/welcome_illustration.svg) top right / cover`,
          marginLeft: 'auto',
        }}
        width="16em"
        height="9em"
        overflow="hidden"
      />
    </Box>
  </Card>
);