import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  List,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  SearchInput,
  TextField,
  TextInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import {
  MobileGrid,
  //SegmentInput,
  UserAside,
} from '..';

const userFilters = [
  <SearchInput source="q" alwaysOn />,
  <NumberInput source="id" />,
  <TextInput source="username" />,
  <NullableBooleanInput source="root" defaultValue />,
  <NullableBooleanInput source="enabled" defaultValue />,
  <DateInput source="createdAt" />,
];

export const UserList = () => {
  const isXsmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <List
      filters={isSmall ? userFilters : undefined}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      aside={<UserAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          {/*<UserLinkField />*/}
          <NumberField source="id" />
          <TextField source="username" />
          <TextField source="customerId" />
          <BooleanField source="root" />
          <BooleanField source="enabled" />
          <DateField source="createdAt" label="Created" /*showTime*/ />
        </Datagrid>
      )}
    </List>
  );
};