import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { ProductImageUrl, Routes } from '../../consts';
import { useShoppingCart } from '../../hooks';
import { getUserToken } from '../../stores';
import { CheckoutService } from '../../services';

interface ShoppingCartPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export const ShoppingCartPopover = (props: ShoppingCartPopoverProps) => {
  const { open, anchorEl, onClose } = props;
  const { items, removeFromCart } = useShoppingCart();
  const currentUser = getUserToken();

  const handleViewCart = () => window.location.href = Routes.cart;

  const handleCheckout = async () => {
    const data = {
      customerId: currentUser?.id,
      productId: 1,
      cart: items,
    };
    const { url } = await CheckoutService.createSession(data);
    window.location.href = url;
  };

  const calculateTotal = () => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <Popover
      id={open ? 'simple-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      //sx={{ minWidth: 600 }}
    >
      <Box p={1} width={360}>
        <Typography variant="h6" gutterBottom textAlign="center">
          Shopping Cart
        </Typography>
        <List dense>
          {items.length > 0 ? items.map((item, index) => (
            <ListItem key={index} dense disablePadding>
              <ListItemAvatar>
                <Avatar src={ProductImageUrl} alt={item.name} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <a
                    href={`/product/${item.id}`}
                    title={`View ${item.name}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {item.name}
                  </a>
                }
                secondary={`Quantity: ${item.quantity} | Price: $${item.price}`}
              />
              <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'right' }}>
                ${item.price * item.quantity}
              </Typography>
              <Tooltip title="Remove from cart" >
                <IconButton size="small" onClick={() => removeFromCart(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          )) : (
            <ListItem>
              <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'center' }}>
                Your cart is empty.
              </Typography>
            </ListItem>
          )}
          {items.length > 0 && (
            <>
              <Divider sx={{p: 1}} />
              <ListItem>
                <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'right' }}>
                  Subtotal: ${calculateTotal()}
                </Typography>
              </ListItem>
            </>
          )}
        </List>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          {window.location.pathname !== Routes.cart && (
            <Button
              variant="contained"
              color="inherit"
              size="small"
              onClick={handleViewCart}
              style={{ marginRight: 5 }}
            >
              View Cart
            </Button>
          )}
          {items.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleCheckout}
            >
              Checkout
            </Button>
          )}
        </div>
      </Box>
    </Popover>
  );
};