import { BooleanField, NumberField, Show, TabbedShowLayout, TextField, usePermissions } from 'react-admin';

import { ProductAside } from './ProductAside';

export const ProductShow = () => {
  const { permissions } = usePermissions();

  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="product.form.summary">
          <NumberField source="id" />
          <TextField source="name" />
          <TextField source="category" />
          <NumberField source="price" />
          <NumberField source="quantity" />
          <BooleanField source="enabled" />
        </TabbedShowLayout.Tab>
        {permissions === 'admin' && (
          <TabbedShowLayout.Tab
            label="product.form.security"
            path="security"
          >
            <TextField source="role" />
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
      <ProductAside />
    </Show>
  );
};