import { Grid, List } from '@mui/material';

import { ProductFeatureListItem } from '..';
import { ProductFeature } from '../../types';

interface ProductFeatureListProps {
  features: ProductFeature[];
};

export const ProductFeatureList = (props: ProductFeatureListProps) => {
  const { features } = props;
  return (
    <List dense>
      <Grid container spacing={2}>
        {features?.map((feature: ProductFeature, index: number) => (
          <Grid item key={index} xs={12} sm={6}>
            <ProductFeatureListItem {...feature} />
          </Grid>
        ))}
      </Grid>
    </List>
  );
};