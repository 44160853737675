import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

import { ProductTestimonial } from '../types';

export const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Alex Smith',
      quote: 'The 3D printed hat bender is a game changer for my millinery business. The precision and quality are unmatched.',
      location: 'New York, USA',
    },
    {
      id: 2,
      name: 'Samantha Lee',
      quote: 'I never knew I needed a hat bender until I tried this one - it’s incredibly easy to use and gives perfect results every time.',
      location: 'London, UK',
    },
    {
      id: 3,
      name: 'Diego Garcia',
      quote: 'As a hat collector, this tool has been an incredible addition to my setup. Highly recommend it!',
      location: 'Madrid, Spain',
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.paper', padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Customer Testimonials
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {testimonials?.map((testimonial: ProductTestimonial, index: number) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  "{testimonial.quote}"
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  - {testimonial.location}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};