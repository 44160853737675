import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { ProductImageUrl, Routes, TaxRate } from '../../consts';
import { OrderModel, ProductModel } from '../../types';

interface OrderSummaryProps {
  order: OrderModel;
};

export const OrderSummary = (props: OrderSummaryProps) => {
  const { order } = props;

  const calculateSubtotal = (products: ProductModel[]) => {
    return products.reduce((total, product) => total + (product?.price ?? 0) * order.quantity, 0);
  };

  const calculateTax = (subtotal: number, taxRate: number): number => {
    return subtotal * taxRate;
  };

  const handleViewProduct = (productId: number) => window.location.href = Routes.product.replace(':id', productId.toString());

  const shippingCost = 4.99;
  const subtotal = calculateSubtotal(order?.products ?? []);
  const taxAmount = calculateTax(subtotal, TaxRate);
  const totalCost = subtotal + taxAmount + shippingCost;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="invoice table">
        <TableHead>
          <TableRow>
            <TableCell><strong>Description</strong></TableCell>
            <TableCell align="right"><strong>Quantity</strong></TableCell>
            <TableCell align="right"><strong>Price</strong></TableCell>
            <TableCell align="right"><strong>Total</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order?.products?.map((product: ProductModel, index: number) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  verticalAlign: 'middle',
                }}
              >
                <img
                  src={ProductImageUrl}
                  alt={product.name}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
                <Typography variant="body1" sx={{display: 'flex', flexDirection: 'column', marginLeft: 2}}>
                  <Tooltip title={`View ${product.name}`}>
                    <a
                      href={`/product/${product.id}`}
                      style={{textDecoration: 'none', color: 'inherit'}}
                      onClick={() => handleViewProduct(parseInt(product?.id?.toString() ?? '0'))}
                    >
                      {product.name}
                    </a>
                  </Tooltip>
                  <small>{product?.description}</small>
                </Typography>
              </TableCell>
              <TableCell align="right">{product.productOrder?.quantity ?? 1}</TableCell>
              <TableCell align="right">${product?.price ?? 0}</TableCell>
              <TableCell align="right">${((product?.price ?? 0) * order.quantity).toFixed(2)}</TableCell>
             </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align="right">${subtotal.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tax</TableCell>
            <TableCell align="right">{`${(TaxRate * 100).toFixed(0)}%`}</TableCell>
            <TableCell align="right">${taxAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Shipping</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">${shippingCost}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align="right">${totalCost.toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};