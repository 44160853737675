import { Box } from '@mui/material';
import { Stars as StarsIcon } from '@mui/icons-material';
import { FieldProps, useRecordContext } from 'react-admin';

interface StarRatingFieldProps {
  size?: 'large' | 'small';
};

export const StarRatingField = ({ size = 'large' }: FieldProps & StarRatingFieldProps) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <Box
      component="span"
      display="flex"
      sx={{
        opacity: 0.87,
        whiteSpace: 'nowrap',
      }}
    >
      {Array(record.rating)
        .fill(true)
        .map((_, i) => (
          <StarsIcon
            key={i}
            sx={{
              width: size === 'large' ? 20 : 15,
              height: size === 'large' ? 20 : 15,
            }}
          />
        ))}
    </Box>
  );
};

StarRatingField.defaultProps = {
  label: 'resources.reviews.fields.rating',
  source: 'rating',
};