import {
  Badge,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  //Brightness4 as Brightness4Icon,
  //Brightness7 as Brightness7Icon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';

import { AccountMenu } from '../..';

interface NavbarHeaderRightProps {
  cartItemsCount: number;
  isAdmin: boolean;
  isAuthenticated: boolean;
  onOpenCart: (event: any) => void;
  onOpenAdmin: () => void;
};

export const NavbarHeaderRight = (props: NavbarHeaderRightProps) => {
  const {
    cartItemsCount, isAdmin, isAuthenticated,
    onOpenCart, onOpenAdmin,
  } = props;
  return (
    <>
      <Tooltip title="View shopping cart">
        <Badge
          badgeContent={cartItemsCount}
          color="error"
          max={99}
          style={{color: 'inherit', marginRight: 3}}
        >
          <IconButton
            size="small"
            onClick={onOpenCart}
            style={{color: 'inherit'}}
          >
            <ShoppingCartIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      {isAdmin && isAuthenticated && (
        <Tooltip title="Admin Dashboard">
          <IconButton
            aria-controls="admin-menu"
            aria-haspopup="true"
            size="small"
            onClick={onOpenAdmin}
            style={{color: 'inherit', marginRight: 3}}
          >
            <AdminPanelSettingsIcon />
          </IconButton>
        </Tooltip>
      )}
      <AccountMenu />
    </>
  );
};
/*
  <Tooltip title="Toggle theme">
    <IconButton
      size="small"
      onClick={toggleColorMode}
      style={{color: 'inherit', marginRight: 3}}
    >
      {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
    </IconButton>
  </Tooltip>
  TODO: <LoadingIndicator />
*/