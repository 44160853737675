import { Avatar, SxProps } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';

import { UserImageUrl } from '../../../consts';
import { UserModel } from '../../../types';

interface AvatarFieldProps extends FieldProps<UserModel> {
  sx?: SxProps;
  size?: string;
};

export const AvatarField = ({ size = '25', sx }: AvatarFieldProps) => {
  const record = useRecordContext<UserModel>();
  if (!record) return null;
  return (
    <Avatar
      src={`${UserImageUrl}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      sx={sx}
      alt={`${record.username}`}
    />
  );
};