import { http } from '../modules';
import { ProductModel } from '../types';

const getProduct = async (id: number) => {
  try {
    const response = await http()
      .get(`products?id=${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getProducts = async () => {
  try {
    const response = await http()
      .get(`products`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const createProduct = async (payload: ProductModel) => {
  try {
    const response = await http()
      .post(`products`, { product: payload });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const updateProduct = async (id: number, payload: ProductModel) => {
  try {
    const response = await http()
      .put(`products?id=${id}`, { product: payload });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const deleteProduct = async (id: number) => {
  try {
    const response = await http()
      .delete(`products?id=${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const ProductService = {
  getProduct,
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
};