import React, { useEffect, useState } from 'react';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  //FavoriteBorder as FavoriteBorderIcon,
  //Share as ShareIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';

import { QuantitySelector } from '.';
import { ProductImageUrl } from '../../consts';
import { ShoppingCartItem } from '../../contexts';
import { ProductModel } from '../../types';

interface ProductListItemProps {
  product: ProductModel;
  onAddToCart: (item: ShoppingCartItem) => void;
};

export const ProductListItem = (props: ProductListItemProps) => {
  const { product, onAddToCart } = props;
  const [showQuantitySelector, setShowQuantitySelector] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleAddToCartClick = () => {
    setShowQuantitySelector(true);
    handleAddToCart(1);
  };

  const handleAddToCart = (quantity: number) => {
    // Clear any existing timeout to reset the timer
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    // Add the product to the cart logic...
    onAddToCart({
      id: product.id!,
      name: product.name,
      description: product.description,
      price: product.price,
      priceId: product.priceId,
      color: 'Black',
      quantity,
    });
  
    // Set a new timeout to hide the quantity selector after 3 seconds
    const id = setTimeout(() => {
      setShowQuantitySelector(false);
      setTimeoutId(null); // Clear the timeout ID when it's complete
    }, 3000);
  
    // Store the timeout ID in state
    setTimeoutId(id);
  };

  useEffect(() => {
    // Clean up timeout on unmount
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt={product.name} src={ProductImageUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={product.name}
        secondary={
          <>
            <Typography component="span" variant="body2" color="text.primary">
              ${product.price}
            </Typography>
            {" — " + product.description}
          </>
        }
      />
      <ListItemSecondaryAction>
        {!showQuantitySelector ? (
          <IconButton
            edge="end"
            aria-label="add to cart"
            size="small"
            onClick={handleAddToCartClick}
          >
            <ShoppingCartIcon />
          </IconButton>
        ) : (
          <QuantitySelector onQuantityChange={handleAddToCart} />
        )}
        {/*
        <IconButton
          edge="end"
          aria-label="favorite"
          size="small"
        >
          <FavoriteBorderIcon />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="share"
          size="small"
        >
          <ShareIcon />
        </IconButton>
        */}
      </ListItemSecondaryAction>
    </ListItem>
  );
};