import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ExpandMoreProps {
  expand: boolean;
  onClick: () => void;
};

export const ExpandMore = styled((props: ExpandMoreProps & React.ComponentProps<typeof IconButton>) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));