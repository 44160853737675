import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {
  AddShoppingCart as ShoppingCartIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Share as ShareIcon,
} from '@mui/icons-material';

import { QuantitySelector } from '.';
import { ProductCategoryImages, Routes } from '../../consts';
import { ShoppingCartItem } from '../../contexts';
import { ProductModel } from '../../types';

interface ProductGridItemProps {
  product: ProductModel;
  onAddToCart: (item: ShoppingCartItem) => void;
};

export const ProductGridItem = (props: ProductGridItemProps) => {
  const { product, onAddToCart } = props;
  const [showQuantitySelector, setShowQuantitySelector] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleAddToCartClick = () => {
    setShowQuantitySelector(true);
    handleAddToCart(1);
  };

  const handleAddToCart = (quantity: number) => {
    // Clear any existing timeout to reset the timer
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    // Add the product to the cart logic...
    onAddToCart({
      id: product.id!,
      name: product.name,
      description: product.description,
      price: product.price,
      priceId: product.priceId,
      color: 'Glow in the Dark',
      quantity,
    });
  
    // Set a new timeout to hide the quantity selector after 3 seconds
    const id = setTimeout(() => {
      setShowQuantitySelector(false);
      setTimeoutId(null); // Clear the timeout ID when it's complete
    }, 3000);
  
    // Store the timeout ID in state
    setTimeoutId(id);
  };

  useEffect(() => {
    // Clean up timeout on unmount
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardActionArea
          href={Routes.product.replace(':id', product.id + '')}
          //style={{flex: '1 0 auto'}}
          style={{
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          <CardMedia
            component="img"
            //height="100%"
            height="150"
            width="auto"
            image={ProductCategoryImages[product.category]}
            alt={product.name}
            style={{
              objectFit: 'contain',
            }}
          />
          <CardContent style={{flexGrow: 1, flexShrink: 0}}>
            <Typography gutterBottom variant="h6" component="h2">
              {product.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ${product.price}
            </Typography>
            <Typography variant="body2">
              {product.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions style={{flexShrink: 0}}>
          {!showQuantitySelector ? (
            <IconButton
              size="small"
              color="primary"
              onClick={handleAddToCartClick}
            >
              <ShoppingCartIcon />
            </IconButton>
          ) : (
            <QuantitySelector onQuantityChange={handleAddToCart} />
          )}
          <Box flexGrow={1} />
          <IconButton size="small" color="primary">
            <FavoriteBorderIcon />
          </IconButton>
          <IconButton size="small" color="primary">
            <ShareIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};