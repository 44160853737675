import { Avatar, SxProps } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';

import { ProductCategoryImages } from '../../../consts';
import { ProductModel } from '../../../types';

interface ProductAvatarFieldProps extends FieldProps {
  size?: string;
  src?: string;
  sx?: SxProps;
};

export const ProductAvatarField = (props: ProductAvatarFieldProps) => {
  const { size = '25', src, sx } = props;
  const record = useRecordContext<ProductModel>();
  if (!record) {
    return null;
  }

  return (
    <Avatar
      src={src ?? ProductCategoryImages[record.category]}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      sx={sx}
      //alt={`${record.username}`}
    />
  );
};