import { Inventory as ProductIcon } from '@mui/icons-material';

import { ProductCreate } from './ProductCreate';
import { ProductEdit } from './ProductEdit';
import { ProductList } from './ProductList';
import { ProductShow } from './ProductShow';

export * from './Poster';
export * from './ProductAside';
export * from './ProductEditDetails';
export * from './ProductEditEmbedded';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  show: ProductShow,
  icon: ProductIcon,
  recordRepresentation: (record: any) => record.name,//`${record.name} (${record.description})`,
};