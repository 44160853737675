import OrderIcon from '@mui/icons-material/AttachMoney';

import { OrderEdit } from './OrderEdit';
import { OrderList } from './OrderList';

export * from './Basket';
export * from './MobileGrid';
export * from './NbItemsField';
export * from './TableCellRight';
export * from './Totals';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: OrderList,
  edit: OrderEdit,
  icon: OrderIcon,
};