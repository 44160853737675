import { Container } from '@mui/material';

import { ShoppingCart } from '../components';

export const ShoppingCartPage = () => {
  return (
    <Container
      maxWidth="md"
      component="main"
      sx={{ pb: 6 }}
    >
      <ShoppingCart />
    </Container>
  );
};