import { amber, blue, green, red } from '@mui/material/colors';

import { OrderStatus } from '../types';

export const substr = (text: string, maxChars: number = 30, addEllipsis: boolean = true) => {
  if (text.length <= maxChars) {
    return text;
  }
  const result = text.substring(0, Math.min(text.length, maxChars));
  return addEllipsis ? result + '...' : result;
};

export const getOrderStatusColor = (status: OrderStatus) => {
  switch (status?.toLowerCase()) {
    case 'pending': return amber[500];
    case 'paid': return green[500];
    case 'processing': return amber[500];
    case 'shipped': return blue[400];
    case 'delivered': return green[500];
    case 'refunded': return amber[700];
    case 'returned': return red[500];
    case 'awaiting_return': return red[500];
    case 'processing_return': return red[600];
    case 'canceled':
    default: return red[500];
  }
};

export const getOrderStatusName = (status: OrderStatus) => {
  switch (status?.toLowerCase()) {
    case 'pending': return 'Pending';
    case 'paid': return 'Paid';
    case 'processing': return 'Processing';
    case 'shipped': return 'Shipped';
    case 'delivered': return 'Delivered';
    case 'refunded': return 'Refunded';
    case 'returned': return 'Returned';
    case 'awaiting_return': return 'Awaiting Return';
    case 'processing_return': return 'Processing Return';
    case 'canceled': return 'Canceled';
    default: return 'Unknown';
  }
};