import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';

import { CardWithIcon } from '.';

export const NbNewOrders = ({ value }: any) => (
  <CardWithIcon
    to="/commands"
    icon={ShoppingCartIcon}
    title="New Orders"
    subtitle={value}
  />
);