import React, { FormEvent } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Routes } from '../consts';

interface FooterProps {
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
};

export const Footer = (props: FooterProps) => {
  const { termsOfServiceUrl, privacyPolicyUrl } = props;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Implement submit logic, e.g., posting to a newsletter subscription API.
    console.log('handleSubmit');
  };

  return (
    <Box
      component="footer"
      color="primary"
      sx={{
        py: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit">
              About Us
            </Typography>
            <Typography variant="body2" color="inherit" paragraph>
              Learn more about our work and the impact of 3D printed hat benders. Our mission is to provide innovative solutions for fashion enthusiasts and professionals alike.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit">
              Quick Links
            </Typography>
            <Link href={Routes.products} color="inherit" variant="body2" display="block" style={{textDecoration: 'none'}}>
              Products
            </Link>
            <Link href={Routes.contact} color="inherit" variant="body2" display="block" style={{textDecoration: 'none'}}>
              Contact
            </Link>
            <Link href={termsOfServiceUrl} color="inherit" variant="body2" display="block" style={{textDecoration: 'none'}}>
              Terms of Service
            </Link>
            <Link href={privacyPolicyUrl} color="inherit" variant="body2" display="block" style={{textDecoration: 'none'}}>
              Privacy Policy
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit">
              Stay Updated
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                variant="filled"
                label="Email Address"
                margin="normal"
                size="small"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                //color="secondary"
              >
                Subscribe
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};