import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import {
  DateInput,
  Edit,
  NullableBooleanInput,
  PasswordInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import {
  UserAside,
  FullNameField,
  //SegmentsInput,
  validateForm,
} from '..';

export const UserEdit = () => {
  return (
    <Edit title={<VisitorTitle />} aside={<UserAside />}>
      <SimpleForm validate={validateForm}>
        <div>
          <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                Identity
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source="firstName"
                    isRequired
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source="lastName"
                    isRequired
                    fullWidth
                  />
                </Box>
              </Box>
              <TextInput
                type="text"
                source="username"
                isRequired
                fullWidth
              />
              <TextInput
                type="email"
                source="email"
                isRequired
                fullWidth
              />
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <DateInput
                    source="birthday"
                    fullWidth
                    helperText={false}
                  />
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} />
              </Box>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Address
              </Typography>
              <TextInput
                source="address"
                multiline
                fullWidth
                helperText={false}
              />
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source="city"
                    fullWidth
                    helperText={false}
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source="stateAbbr"
                    fullWidth
                    helperText={false}
                  />
                </Box>
                <Box flex={2}>
                  <TextInput
                    source="zipcode"
                    fullWidth
                    helperText={false}
                  />
                </Box>
              </Box>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Change Password
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <PasswordInput
                    source="password"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <PasswordInput
                    source="confirmPassword"
                    fullWidth
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Stats
              </Typography>

              {/* TODO: <SegmentsInput fullWidth /> */}
              <NullableBooleanInput
                fullWidth
                source="hasNewsletter"
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const VisitorTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;