import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import {
  a11yProps,
  BreadcrumbItem,
  Breadcrumbs,
  OrderDetails,
  TabPanel,
} from '../../components';
import { ReturnStatuses, Routes } from '../../consts';
import { OrderService } from '../../services';
import { getUserToken } from '../../stores';
import { OrderModel } from '../../types';

const crumbs: BreadcrumbItem[] = [{
  text: 'Home',
  href: Routes.home,
  selected: false,
},{
  text: 'Orders',
  href: Routes.account.orders,
  selected: true,
}];

export const OrdersPage = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [value, setValue] = useState(0);
  const currentUser = getUserToken();

  const productOrders = orders?.filter((order: any) => !ReturnStatuses.includes(order.status)) ?? [];
  const returns = orders?.filter((order: any) => ReturnStatuses.includes(order.status)) ?? [];

  const handleReloadOrders = useCallback(() => {
    OrderService.getOrders(currentUser?.id).then((response: any) => {
      console.log('getOrders response:', response);
      if (response?.status !== 'ok') {
        // TODO: Error
        return;
      }

      setOrders(response?.data);
    });
  }, [currentUser?.id]);

  const handleChange = (event: any, newValue: number) => setValue(newValue);

  useEffect(() => handleReloadOrders(), [handleReloadOrders]);

  return (
    <Container style={{ height: 'auto' }}>
      <Breadcrumbs crumbs={crumbs} />
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          onChange={handleChange}
        >
          <Tab label="Orders" {...a11yProps(0)} />
          <Tab label="Returns" {...a11yProps(1)} />
        </Tabs>
        <Box sx={{ width: '100%' }}>
          <TabPanel value={value} index={0}>
            {/*
            <Typography variant="h4" gutterBottom textAlign="center">
              Orders
            </Typography>
            */}
      
            {productOrders?.length! > 0 ? productOrders?.map((order: OrderModel, index: number) => (
              <OrderDetails
                canExpand
                key={index}
                order={order}
              />
            )) : (
              <Typography variant="body1" gutterBottom textAlign="center">
                You have not placed any orders yet.
              </Typography>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/*
            <Typography variant="h4" gutterBottom textAlign="center">
              Returns
            </Typography>
            */}
  
            {returns?.length! > 0 ? returns?.map((order: OrderModel, index: number) => (
              <OrderDetails
                canExpand
                key={index}
                order={order}
              />
            )) : (
              <Typography variant="body1" gutterBottom textAlign="center">
                You have not returned any orders yet.
              </Typography>
            )}
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};