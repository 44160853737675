import { People as PeopleIcon } from '@mui/icons-material';

import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';
import { UserList } from './UserList';
import { UserShow } from './UserShow';

export * from './UserAside';
export * from './UserCreate';
export * from './UserEditEmbedded';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow,
  icon: PeopleIcon,
  recordRepresentation: (record: any) => `${record.name} (${record.root})`,
};