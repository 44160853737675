import { SxProps, Typography } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';

import { AvatarField } from '.';
import { UserModel } from '../../../types';

interface FullNameFieldProps extends FieldProps<UserModel> {
  size?: string;
  sx?: SxProps;
};

export const FullNameField = (props: FullNameFieldProps) => {
  const { size, sx } = props;
  const record = useRecordContext<UserModel>();
  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={sx}
    >
      <AvatarField
        record={record}
        size={size}
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      />
      {record.username}
    </Typography>
  ) : null;
};

FullNameField.defaultProps = {
  source: 'username' as const,
  label: 'Name',
};