import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

interface CustomSelectProps {
  name: string;
  required: boolean;
  label: string;
  values: string[];
  onChange: (value: string) => void;
};

export const CustomSelect = (props: CustomSelectProps) => {
  const { name, required = true, label, values, onChange } = props;
  const [selected, setSelected] = useState('');

  const handleChange = (event: any) => {
    const reason = event.target.value;
    setSelected(reason);
    onChange(reason);
  };

  return (
    <FormControl fullWidth sx={{marginBottom: 1}}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        required={required}
        value={selected}
        label={label}
        error={!selected}
        //helperText={!selected ? label + ' are required' : ''}
        onChange={handleChange}
      >
        {values.map((value: string, index: number) => (
          <MenuItem key={index} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};