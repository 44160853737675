import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecordContext } from 'react-admin';

import { TableCellRight } from '.';
import { TaxRate } from '../../../consts';
import { OrderModel } from '../../../types';

export const Totals = () => {
  const record = useRecordContext<OrderModel>();

  return (
    <Table sx={{ minWidth: '35em' }}>
      <TableBody>
        {/*
        <TableRow>
          <TableCell>
            Subtotal
          </TableCell>
          <TableCellRight>
            {record.products?.subtotal.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCellRight>
        </TableRow>
        */}
        <TableRow>
          <TableCell>
            Tax ({TaxRate.toLocaleString(undefined, {
              style: 'percent',
            })})
          </TableCell>
          {/*
          <TableCellRight>
            {record?.taxes.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCellRight>
          */}
        </TableRow>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>
            Total
          </TableCell>
          <TableCellRight sx={{ fontWeight: 'bold' }}>
            {record?.amount.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCellRight>
        </TableRow>
      </TableBody>
    </Table>
  );
};