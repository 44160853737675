import * as React from 'react';
import { Create, required, TabbedForm, TextInput } from 'react-admin';

import { ProductEditDetails } from '.';

const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
    default: module.RichTextInput,
  }))
);

export const ProductCreate = () => (
  <Create>
    <TabbedForm defaultValues={{ sales: 0 }}>
      <TabbedForm.Tab
        label="Image"
        sx={{ maxWidth: '40em' }}
      >
        <TextInput
          autoFocus
          source="image"
          fullWidth
          validate={required()}
        />
        <TextInput source="thumbnail" fullWidth validate={required()} />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="Details"
        path="details"
        sx={{ maxWidth: '40em' }}
      >
        <ProductEditDetails />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="Description"
        path="description"
      >
        <RichTextInput source="description" />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
);