import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  ClearAll as ClearAllIcon,
  Delete as DeleteIcon,
  PointOfSale as PointOfSaleIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';
import { Identifier } from 'react-admin';

import { ProductImageUrl } from '../../consts';
import { ShoppingCartItem } from '../../contexts';
import { useShoppingCart } from '../../hooks';
import { CheckoutService } from '../../services';
import { getUserToken } from '../../stores';

export const ShoppingCart = () => {
  const { items, removeFromCart, clearCart, setQuantity } = useShoppingCart();
  const currentUser = getUserToken();

  const handleQuantityChange = (id: Identifier, quantity: number) => {
    if (quantity <= 0) {
      removeFromCart(id);
    } else {
      setQuantity(id, quantity);
    }
  };

  const handleClearCart = () => {
    const result = window.confirm(`Are you sure you want to clear your shopping cart?`);
    if (!result) {
      return;
    }

    clearCart();
  };

  const handleCheckout = async () => {
    const data = {
      customerId: currentUser?.id,
      productId: 1,
      cart: items,
    };
    const { url } = await CheckoutService.createSession(data);
    window.location.href = url;
  };

  const calculateTotal = () => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom textAlign="center">
        Shopping Cart
      </Typography>
      <Box display="flex" justifyContent="end" alignItems="center" mt={3}>
        {items.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleCheckout}
          >
            <PointOfSaleIcon />
            &nbsp;Checkout
          </Button>
        )}
      </Box>
      <List
        disablePadding
        dense
        sx={{ width: '100%', bgcolor: 'background.paper' }}
      >
        {items.length > 0 ? items.map((item: ShoppingCartItem, index: number) => (
          <Paper
            key={index}
            variant="outlined"
            sx={{
              my: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 1 auto' }}>
              <ListItemButton
                //href={`/product/${item.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = `/product/${item.id}`;
                }}
              >
                <Grid container spacing={0} style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
                  <Grid item xs={1}>
                    <ListItemAvatar>
                      <Avatar src={ProductImageUrl} alt={item.name} />
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs={6}>
                    <ListItemText
                      primary={item.name}
                      secondary={item.description}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleQuantityChange(item.id, item.quantity - 1);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <TextField
                        size="small"
                        type="number"
                        value={item.quantity}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleQuantityChange(item.id!, parseInt(e.target.value));
                        }}
                        inputProps={{ min: 1, style: { textAlign: 'center' } }}
                        sx={{ width: '60px', mx: 0 }}
                      />
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleQuantityChange(item.id!, item.quantity + 1);
                        }}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">
                      <small>${item.price}</small>
                    </Typography>
                    <Typography variant="subtitle1" /*sx={{me: 1}}*/>
                      ${item.price * item.quantity}
                    </Typography>
                    <ListItemSecondaryAction>
                      <Tooltip title="Remove from cart">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFromCart(item.id!);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </Grid>
                </Grid>
              </ListItemButton>
            </Box>
          </Paper>
        )) : (
          <Typography variant="h6" gutterBottom textAlign="center">
            Your shopping cart is empty.
          </Typography>
        )}
      </List>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {(items.length > 0 && (
          <>
            <Tooltip title="Clear all items in shopping cart">
              <Button
                color="error"
                size="small"
                variant="contained"
                onClick={handleClearCart}
                sx={{
                  top: 0,
                  right: 0,
                }}
              >
                <ClearAllIcon />
                &nbsp;Clear Cart
              </Button>
            </Tooltip>
            <Typography
              variant="h6"
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              Subtotal: ${calculateTotal()}
            </Typography>
          </>
        ))}
      </Box>
    </Container>
  );
};