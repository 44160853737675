import { Button } from '@mui/material';

import { useShoppingCart } from '../hooks';
import { CheckoutService } from '../services';
import { getUserToken } from '../stores';

interface CheckoutButtonProps {
  price?: number;
};

export const CheckoutButton = (props: CheckoutButtonProps) => {
  const { price = 9.99 } = props;
  const currentUser = getUserToken();

  const { items } = useShoppingCart();

  const handleCheckout = async () => {
    const data = {
      customerId: currentUser?.id,
      cart: items,
    };
    const { url } = await CheckoutService.createSession(data);
    window.location.href = url;

    //const { sessionId } = await response.json();
    //const stripe = await stripePromise;
    //const result = await stripe!.redirectToCheckout({ sessionId });
    ////console.log('redirect to checkout result:', result);
    //if (result.error) {
    //  // If `redirectToCheckout` fails due to a browser or network
    //  // error, display the localized error message to your customer
    //  // using `result.error.message`.
    //  console.error(result.error.message);
    //  return;
    //}
  };

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={handleCheckout}
    >
      Buy Now - ${price}
    </Button>
  );
};