import React, { useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreVertIcon,
  Receipt as ReceiptIcon,
} from '@mui/icons-material';

import { ExpandMore, OrderSummary } from '..';
import { OrderIdMultiplier, ProductImageUrl, Routes } from '../../consts';
import { getOrderStatusColor, getOrderStatusName } from '../../modules';
import { OrderModel } from '../../types';

interface OrderDetailsProps {
  canExpand: boolean;
  order: OrderModel;
};

export const OrderDetails = (props: OrderDetailsProps) => {
  const { canExpand, order } = props;
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openOrderMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  
  const handleExpandClick = () => setExpanded(!expanded);

  const handleTrackPackage = () => {
    console.log('handleTrackPackage:', order);
    handleCloseMenu();
  };

  const handleReturnOrder = () => {
    window.location.href = Routes.account.return.replace(':id', order?.id!.toString());
    handleCloseMenu();
  };

  const handleWriteReview = () => {
    console.log('handleWriteReview:', order);
    handleCloseMenu();
  };

  return (
    <Card
      color="primary"
      sx={{
        border: '1px solid rgba(200, 200, 200, 0.5)',
        borderRadius: 2,
        marginBottom: 2,
      }}
    >
      <CardHeader
        //avatar={
        //  <Avatar sx={{ bgcolor: getOrderStatusColor(order.status) }}>
        //    <ReceiptIcon />
        //  </Avatar>
        //}
        title={
          <Tooltip title="Click to view order details">
            <a
              href={`/account/order/${order?.id}`}
              //target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              Order #{order?.id! * OrderIdMultiplier}
            </a>
          </Tooltip>
        }
        titleTypographyProps={{
          sx: {
            fontWeight: 'bold',
          },
        }}
        subheader={`Placed on ${new Date(order?.createdAt!).toLocaleString()}`}
        action={
          <div style={{marginTop: 8, marginRight: 8, display: 'flex'}}>
            <Tooltip title="Click to view invoice">
              <a
                href={order?.invoiceUrl!}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: 'flex',
                  marginTop: 12,
                  textDecoration: 'none',
                  color: 'inherit',
                  cursor: 'pointer',
                }}
              >
                <ReceiptIcon
                  style={{color: 'dodgerblue'}}
                  onClick={() => window.open(order?.invoiceUrl ?? '', '_blank')}
                />
                &nbsp;
                <span style={{color: getOrderStatusColor(order?.status)}}>
                  {getOrderStatusName(order?.status)}
                </span>
              </a>
            </Tooltip>
            &nbsp;
            <IconButton
              size="small"
              onClick={handleOpenMenu}
              style={{
                marginTop: 8,
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              keepMounted
              id="card-menu"
              open={openOrderMenu}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleTrackPackage}>Track Package</MenuItem>
              <MenuItem onClick={handleReturnOrder}>Return Item(s)</MenuItem>
              <MenuItem onClick={handleWriteReview}>Write Review</MenuItem>
            </Menu>
          </div>
        }
      />
      <CardContent>
        {/* Summary information when collapsed */}
        {canExpand && !expanded && (
          <Grid container spacing={2} alignItems="center">
            {order?.products?.map((product, index) => (
              <React.Fragment key={index}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    component="img"
                    src={ProductImageUrl}
                    alt={product?.name}
                    sx={{
                      width: '40px',
                      height: 'auto',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                </Grid>
                <Grid item xs sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" noWrap>
                      {product?.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Quantity: {product.productOrder?.quantity ?? 1}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                  <Typography variant="subtitle1">
                    ${product.price}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}

        {/* Detailed OrderSummary within the Collapse */}
        {canExpand ? (
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <OrderSummary order={order} />
          </Collapse>
        ) : (
          <OrderSummary order={order} />
        )}
      </CardContent>
      {canExpand && (
        <CardActions
          disableSpacing
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              textAlign: 'center',
              flexGrow: 1,
              cursor: 'pointer',
              ':hover': {
                color: 'dodgerblue',
              },
            }}
            onClick={handleExpandClick}
          >
            {expanded ? 'Hide Order Details' : 'Show Order Details'}
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            size="small"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      )}
    </Card>
  );
};