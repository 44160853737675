import {
  Container,
  Paper,
  Typography,
} from '@mui/material';

import {
  BreadcrumbItem,
  Breadcrumbs,
  ChangePassword,
  ThemeSelector,
} from '../../components';
import { Routes } from '../../consts';
import { useColorMode } from '../../hooks';

const crumbs: BreadcrumbItem[] = [{
  text: 'Home',
  href: Routes.home,
  selected: false,
},{
  text: 'Settings',
  href: Routes.account.settings,
  selected: true,
}];

export const SettingsPage = () => {
  const { mode, setColorMode } = useColorMode();

  return (
    <Container style={{ height: '70vh' }}>
      <Breadcrumbs crumbs={crumbs} />

      <Typography variant="h4" gutterBottom textAlign="center">
        Account Settings
      </Typography>

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Container component={Paper} elevation={0} style={{ padding: '20px', marginTop: '20px', border: '1px solid grey' }}>
          <Typography variant="h6" align="center" gutterBottom>
            Theme
          </Typography>
          <ThemeSelector
            theme={mode}
            onThemeChange={setColorMode}
          />
        </Container>

        <Container component={Paper} elevation={1} style={{ padding: '20px', marginTop: '20px', border: '1px solid grey' }}>
          <Typography variant="h6" align="center" gutterBottom>
            Change Password
          </Typography>
          <ChangePassword />
        </Container>
      </div>
    </Container>
  );
};