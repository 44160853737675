import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import { Admin, Resource } from 'react-admin';
import { Container, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  AppLayout,
  Dashboard,
  Footer,
  NavbarHeader,
  ProtectedRoute,
} from './components';
import OrdersResource from './components/Admin/Orders';
import ProductsResource from './components/Admin/Products';
import UsersResource from './components/Admin/Users';
import { Routes } from './consts';
import { ShoppingCartProvider } from './contexts';
import {
  ContactPage,
  HomePage,
  LoginPage,
  NotFoundPage,
  OrderPage,
  OrderResponsePage,
  OrdersPage,
  PrivacyPolicyPage,
  ProductPage,
  ProductsPage,
  RegisterPage,
  ReturnOrderPage,
  SettingsPage,
  ShoppingCartPage,
  TermsOfServicePage,
} from './pages';
import { JsonServerProvider, UserAuthProvider } from './providers';

const AdminRoutes = ({ theme }: any) => (
  <Admin
    title="Admin Dashboard"
    authProvider={UserAuthProvider}
    dataProvider={JsonServerProvider}
    dashboard={Dashboard}
    layout={AppLayout}
    //requireAuth={false}
    basename="/admin"
    theme={theme}
  >
    <Resource name="orders" intent="route" {...OrdersResource} />
    <Resource name="products" intent="route" {...ProductsResource} />
    <Resource name="users" intent="route" options={{label: 'Customers'}} {...UsersResource} />
  </Admin>
);

//https://marmelab.com/react-admin-demo
export const App = () => {
  const theme = useTheme();
  const isAdminRoute = window.location.pathname.startsWith('/admin');

  return (
    <ShoppingCartProvider>
      {!isAdminRoute && (
        <NavbarHeader
          title="The Bill Bender Co."
          bgColor="primary" //default
          textColor="default" //inherit
          dense={false}
          elevation={0}
          position="static"
        />
      )}
      <Container
        component={Paper}
        elevation={1}
        style={{
          borderRadius: 8,
          maxWidth: '100%',
          padding: isAdminRoute ? 0 : 16,
        }}
      >
        <Router>
          <Switch>
            <Route path="*" element={<NotFoundPage />} />
            <Route index element={<HomePage />} />
            <Route path={Routes.login} element={<LoginPage />} />
            <Route path={Routes.register} element={<RegisterPage />} />
            <Route path={Routes.products} element={<ProductsPage />} />
            <Route path={Routes.product} element={<ProductPage />} />
            <Route path={Routes.cart} element={<ShoppingCartPage />} />
            <Route path={Routes.order.success} element={<OrderResponsePage />} />
            <Route path={Routes.order.canceled} element={<OrderResponsePage />} />
            <Route path={Routes.contact} element={<ContactPage />} />
            <Route path={Routes.tos} element={<TermsOfServicePage />} />
            <Route path={Routes.privacy} element={<PrivacyPolicyPage/>} />

            <Route path={'/admin/*'} element={<AdminRoutes theme={theme} />} />

            <Route path={Routes.account.dashboard} element={<ProtectedRoute />}>
              <Route path={Routes.account.order} element={<OrderPage />} />
              <Route path={Routes.account.orders} element={<OrdersPage />} />
              <Route path={Routes.account.return} element={<ReturnOrderPage />} />
              <Route path={Routes.account.settings} element={<SettingsPage />} />
            </Route>
          </Switch>
        </Router>
      </Container>
      <Footer
        termsOfServiceUrl={Routes.tos}
        privacyPolicyUrl={Routes.privacy}
      />
    </ShoppingCartProvider>
  );
};