import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
  AttachMoney as MoneyIcon,
} from '@mui/icons-material';
import {
  DashboardMenuItem,
  Menu,
  MenuProps,
  ResourceMenuItem,
  useSidebarState,
} from 'react-admin';

import { SubMenu } from '.';

type MenuName = 'menuSales';

export const AppMenu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuSales: true,
    menuUsers: true,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
};

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        //marginTop: 1,
        marginBottom: 1,
        transition: (theme: any) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <Menu dense={dense}>
        <DashboardMenuItem />
        <SubMenu
          handleToggle={() => handleToggle('menuSales')}
          isOpen={state.menuSales}
          name="Sales"
          icon={<MoneyIcon />}
          dense={dense}
        >
          <ResourceMenuItem name="orders" />
          <ResourceMenuItem name="products" />
        </SubMenu>
        <ResourceMenuItem name="users" />
      </Menu>
    </Box>
  );
};