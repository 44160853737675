import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
} from '@mui/material';

import { BreadcrumbItem, Breadcrumbs, OrderDetails } from '../../components';
import { OrderIdMultiplier, Routes } from '../../consts';
import { OrderService } from '../../services';
import { OrderModel } from '../../types';

export const OrderPage = () => {
  const { id } = useParams();
  const [order, setOrder] = useState<OrderModel>();

  const crumbs: BreadcrumbItem[] = [{
    text: 'Home',
    href: Routes.home,
    selected: false,
  },{
    text: 'Orders',
    href: Routes.account.orders,
    selected: false,
  },{
    text: `Order #${order?.id! * OrderIdMultiplier}`,
    href: Routes.account.order,
    selected: true,
  }];

  const handleReloadOrders = useCallback(() => {
    const orderId = parseInt(id!);
    OrderService.getOrder(orderId).then((response: any) => {
      //console.log('getOrder response:', response);
      if (response?.status !== 'ok') {
        // TODO: Error
        return;
      }

      setOrder(response?.data);
    });
  }, [id]);

  useEffect(() => handleReloadOrders(), [handleReloadOrders]);

  return (
    <Container style={{ height: 'auto' }}>
      <Breadcrumbs crumbs={crumbs} />

      <Typography variant="h4" gutterBottom textAlign="center">
        Order #{order?.id! * OrderIdMultiplier}
      </Typography>
      <OrderDetails
        canExpand={false}
        order={order!}
      />
    </Container>
  );
};