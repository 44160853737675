import React, { createContext, useEffect, useState } from 'react';
import { Identifier } from 'react-admin';

import { StorageKeys } from '../consts';

export interface ShoppingCartItem {
  id: Identifier;
  name: string;
  description: string;
  color: string;
  price: number;
  priceId: string;
  quantity: number;
};

interface ShoppingCartContextType {
  items: ShoppingCartItem[];
  addToCart: (item: ShoppingCartItem) => void;
  removeFromCart: (productId: Identifier) => void;
  clearCart: () => void;
  setQuantity: (productId: Identifier, quantity: number) => void;
};

export const ShoppingCartContext = createContext<ShoppingCartContextType | undefined>(undefined);

export const ShoppingCartProvider = (props: any) => {
  const { children } = props;
  const [items, setItems] = useState<ShoppingCartItem[]>(() => {
    // Load cart items from localStorage
    const savedCartItems = localStorage.getItem(StorageKeys.CartItems);
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  const addToCart = (newItem: ShoppingCartItem) => {
    setItems(prevItems => {
      const itemExists = prevItems.find(item => parseInt(item.id.toString()) === newItem.id);
      if (itemExists) {
        return prevItems.map(item =>
          item.id === newItem.id
            ? { ...item, quantity: item.quantity + newItem.quantity }
            : item
        );
      }
      const newItems = [...prevItems, newItem];
      return newItems;
    });
  };

  const setQuantity = (productId: Identifier, quantity: number) => {
    setItems((prevItems) => prevItems.map(item => 
      item.id === productId ? { ...item, quantity } : item
    ));    
  };

  const removeFromCart = (productId: Identifier) => {
    setItems((prevItems) => prevItems.filter(item => item.id !== productId));
  };

  const clearCart = () => setItems([]);

  useEffect(() => {
    // Save cart items to localStorage whenever items change
    localStorage.setItem(StorageKeys.CartItems, JSON.stringify(items));
  }, [items]);

  return (
    <ShoppingCartContext.Provider
      value={{
        items,
        addToCart,
        removeFromCart,
        clearCart,
        setQuantity,
        //increaseQuantity,
        //decreaseQuantity,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};