import { BooleanField, NumberField, Show, TabbedShowLayout, TextField, usePermissions } from 'react-admin';

import { UserAside } from './UserAside';

export const UserShow = () => {
  const { permissions } = usePermissions();

  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="user.form.summary">
          <NumberField source="id" />
          <TextField source="username" />
          <TextField source="customerId" />
          <BooleanField source="root" />
          <BooleanField source="enabled" />
        </TabbedShowLayout.Tab>
        {permissions === 'admin' && (
          <TabbedShowLayout.Tab
            label="user.form.security"
            path="security"
          >
            <TextField source="role" />
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
      <UserAside />
    </Show>
  );
};