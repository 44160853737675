import * as React from 'react';
import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Pagination,
  ReferenceManyField,
  ReferenceManyCount,
  required,
  TabbedForm,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';

import {
  //CreateRelatedReviewButton,
  Poster,
  ProductEditDetails,
  StarRatingField,
  UserReferenceField,
} from '..';
import { ProductModel } from '../../../types';

const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
    default: module.RichTextInput,
  }))
);

const ProductTitle = () => {
  const record = useRecordContext<ProductModel>();
  return record ? <span>Poster "{record.name}"</span> : null;
};

export const ProductEdit = () => (
  <Edit title={<ProductTitle />}>
    <TabbedForm>
      <TabbedForm.Tab
        label="Image"
        sx={{ maxWidth: '40em' }}
      >
        <Poster />
        <TextInput source="image" fullWidth validate={req} />
        <TextInput source="thumbnail" fullWidth validate={req} />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="Details"
        path="details"
        sx={{ maxWidth: '40em' }}
      >
        <ProductEditDetails />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="Description"
        path="description"
        sx={{ maxWidth: '40em' }}
      >
        <RichTextInput source="description" label="" validate={req} />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="Reviews"
        count={
          <ReferenceManyCount
            reference="reviews"
            target="product_id"
            sx={{ lineHeight: 'inherit' }}
          />
        }
        path="reviews"
      >
        <ReferenceManyField
          reference="reviews"
          target="product_id"
          pagination={<Pagination />}
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .column-comment': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <DateField source="date" />
            <UserReferenceField />
            <StarRatingField />
            <TextField source="comment" />
            <TextField source="status" />
            <EditButton />
          </Datagrid>
          {/* TODO: <CreateRelatedReviewButton /> */}
        </ReferenceManyField>
      </TabbedForm.Tab>
    </TabbedForm>
  </Edit>
);

const req = [required()];