import {
  Container,
  Typography,
} from '@mui/material';
import {
} from '@mui/icons-material';

export const ContactPage = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom textAlign="center">
        Contact
      </Typography>
    </Container>
  );
};