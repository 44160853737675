import { FieldProps, ReferenceField, ReferenceFieldProps, useRecordContext } from 'react-admin';
import { SxProps, Typography } from '@mui/material';

import { ProductModel } from '../../../types';

export const ProductsReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string;
  }
) => (
  <ReferenceField source="id" reference="products" {...props}>
    <ProductNameField />
  </ReferenceField>
);

ProductsReferenceField.defaultProps = {
  source: 'id',
};

interface ProductNameFieldProps extends FieldProps<ProductModel> {
  size?: string;
  sx?: SxProps;
};

export const ProductNameField = (props: ProductNameFieldProps) => {
  const { sx } = props;
  const record = useRecordContext<ProductModel>();

  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={sx}
    >
      {record.name}
    </Typography>
  ) : null;
};