import { Layout } from 'react-admin';

import { AppMenu, NavbarHeader } from '../..';

const Nav = () => (
  <NavbarHeader
    title="The Bill Bender Co."
    bgColor="primary" //default
    textColor="default" //inherit
    dense={false}
    elevation={0}
    position="static"
  />
);

export const AppLayout = (props: any) => (
  <Layout
    //appBar={() => null}
    appBar={Nav}
    sidebar={AppMenu}
    children={props.children}
    sx={{
      display: 'flex',
      '& .RaLayout-appFrame': { marginTop: 0 },
    }}
  />
);