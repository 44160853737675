import { http } from '../modules';

const getConfig = async () => {
  try {
    const response = await http()
      .get(`checkout/config`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getStatus = async (sessionId: string) => {
  try {
    const response = await http()
      .get(`checkout/status?session_id=${sessionId}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const createSession = async (payload: any) => {
  try {
    const response = await http()
      .post(`checkout/create`, payload);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const CheckoutService = {
  getConfig,
  getStatus,
  createSession,
};