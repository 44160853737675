export const AppLogo = ({
  src = '/logo192.png', alt = 'logo',
  width = 48, height = 48,
  ...rest
}) => (
  <img
    src={src}
    alt={alt}
    width={width}
    height={height}
    {...rest}
  />
);