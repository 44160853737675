import { Box, Typography } from '@mui/material';
import {
  Create,
  DateInput,
  email,
  PasswordInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

export const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required';
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required';
  }
  if (!values.email) {
    errors.email = 'ra.validation.required';
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }
  return errors;
};

export const UserCreate = () => (
  <Create>
    <SimpleForm
      sx={{ maxWidth: 500 }}
      // Here for the GQL provider
      defaultValues={{
        birthday: new Date(),
        firstSeen: new Date(),
        lastSeen: new Date(),
        hasOrdered: false,
        latestPurchase: new Date(),
        hasNewsletter: false,
        groups: [],
        nbCommands: 0,
        totalSpent: 0,
      }}
      validate={validateForm}
    >
      <SectionTitle label="Identity" />
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="firstName" isRequired fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="lastName" isRequired fullWidth />
        </Box>
      </Box>
      <TextInput type="text" source="username" isRequired fullWidth />
      <TextInput type="email" source="email" isRequired fullWidth />
      <DateInput source="birthday" />
      <Separator />
      <SectionTitle label="Address" />
      <TextInput
        source="address"
        multiline
        fullWidth
        helperText={false}
      />
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="city" fullWidth helperText={false} />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            source="stateAbbr"
            fullWidth
            helperText={false}
          />
        </Box>
        <Box flex={2}>
          <TextInput source="zipcode" fullWidth helperText={false} />
        </Box>
      </Box>
      <Separator />
      <SectionTitle label="Password" />
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <PasswordInput source="password" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <PasswordInput source="confirmPassword" fullWidth />
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;