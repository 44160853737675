import { Link } from 'react-router-dom';
import { Avatar, Box, Button } from '@mui/material';
import { Person as CustomerIcon } from '@mui/icons-material';
import {
  ListBase,
  SimpleList,
  WithListContext,
} from 'react-admin';
import { subDays } from 'date-fns';

import { CardWithIcon } from '.';
import { UserImageUrl } from '../../../consts';

export const NewCustomers = () => {
  const aMonthAgo = subDays(new Date(), 30);
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);
  aMonthAgo.setHours(0);
  aMonthAgo.setMinutes(0);
  aMonthAgo.setSeconds(0);
  aMonthAgo.setMilliseconds(0);

  return (
    <ListBase
      resource="users"
      filter={{
        has_ordered: true,
        first_seen_gte: aMonthAgo.toISOString(),
      }}
      sort={{ field: 'username', order: 'DESC' }}
      perPage={100}
      disableSyncWithLocation
    >
      <CardWithIcon
        to="/users"
        icon={CustomerIcon}
        title="New Customers"
        subtitle={
          <WithListContext render={({ total }) => <>{total}</>} />
        }
      >
        <SimpleList<any> // TODO: UserModel
          primaryText="%{username}"
          leftAvatar={customer => (
            <Avatar
              src={`${UserImageUrl}?size=32x32`}
              alt={`${customer.username}`}
            />
          )}
        />
        <Box flexGrow={1}>&nbsp;</Box>
        <Button
          sx={{ borderRadius: 0 }}
          component={Link}
          to="/users"
          size="small"
          color="primary"
        >
          <Box p={1} sx={{ color: 'primary.main' }}>
            See All Customers
          </Box>
        </Button>
      </CardWithIcon>
    </ListBase>
  );
};