import { http } from '../modules';
import { OrderModel, ReturnOrderRequest } from '../types';

const getOrder = async (id: number) => {
  try {
    const response = await http()
      .get(`orders?id=${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getOrders = async (customerId: string) => {
  try {
    const response = await http()
      .get(`orders?customerId=${customerId}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const createOrder = async (payload: OrderModel) => {
  try {
    const response = await http()
      .post(`orders`, { order: payload });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const updateOrder = async (id: number, payload: OrderModel) => {
  try {
    const response = await http()
      .put(`orders?id=${id}`, { order: payload });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const deleteOrder = async (id: number) => {
  try {
    const response = await http()
      .delete(`orders?id=${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const returnOrder = async (payload: ReturnOrderRequest) => {
  try {
    const response = await http()
      .post(`orders/returns`, payload);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const OrderService = {
  getOrder,
  getOrders,
  createOrder,
  updateOrder,
  deleteOrder,
  returnOrder,
};