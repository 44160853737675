import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';

import { CustomSelect } from '../../components';
import { ReturnResponses, Routes } from '../../consts';
import { OrderService } from '../../services';
import { OrderModel, ReturnOrderRequest } from '../../types';

interface ReturnOrderState {
  orderId: number;
  reason: string;
  comments: string;
  error: boolean;
};

export const ReturnOrderPage = () => {
  const { id } = useParams();
  const orderId = parseInt(id?.toString()!);
  const [order, setOrder] = useState<OrderModel>();
  const [state, setState] = useState<ReturnOrderState>({
    orderId,
    reason: '',
    comments: '',
    error: false,
  });

  const handleSubmitReturn = () => {
    if (state.orderId === 0 ||
        !state.reason || state.reason === '' ||
        !state.comments || state.comments === '') {
      // TODO: Error
      setState({...state, error: true});
      return;
    }

    const payload: ReturnOrderRequest = {
      orderId: state.orderId,
      reason: state.reason,
      comments: state.comments,
      status: 'processing_return',
    };
    OrderService.returnOrder(payload).then((response: any) => {
      console.log('returnOrder response:', response);
      if (response?.status !== 'ok') {
        // TODO: Error
        return;
      }

      //const returnResponse = response.return;
      window.location.href = Routes.account.orders;
    });
  };

  const handleReloadOrders = useCallback(() => {
    const orderId = parseInt(id?.toString()!);
    OrderService.getOrder(orderId).then((response: any) => {
      if (response?.status !== 'ok') {
        // TODO: Error
        return;
      }

      setOrder(response?.data);
    });
  }, [id]);

  const handleChange = (event: any) => setState({...state, [event.target.name]: event.target.value});

  useEffect(() => handleReloadOrders(), [handleReloadOrders]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Return Order - {order?.products?.map((p: any) => p.name).join(', ')}
      </Typography>
      <CustomSelect
        name="reason"
        required={true}
        label="Reason for return"
        values={ReturnResponses}
        onChange={(value) => setState({...state, reason: value})}
      />

      <FormControl fullWidth sx={{mb: 3}}>
        <InputLabel>Comments</InputLabel>
        <TextField
          multiline
          required
          name="comments"
          rows={3}
          error={state.error}
          helperText={!state.error ? '' : 'Comments are required'}
          onChange={handleChange}
        />
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleSubmitReturn}
      >
        Submit Return
      </Button>
    </Container>
  );
};