import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  PropTypes,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBarProps, useSidebarState } from 'react-admin';

import { AppBrand, NavbarHeaderRight, ShoppingCartPopover } from '../..';
import { ActiveMenuItemColor, NavItems, Routes, StorageKeys, Title } from '../../../consts';
import { useColorMode, useShoppingCart } from '../../../hooks';
import { get } from '../../../modules';
import { getUserToken } from '../../../stores';
import { ElementPosition } from '../../../types';

interface NavbarHeaderProps {
  title: string;
  bgColor: PropTypes.Color;
  textColor: PropTypes.Color;
  dense: boolean;
  elevation: number;
  position: ElementPosition | undefined;
};

export const NavbarHeader = (props: NavbarHeaderProps & AppBarProps) => {
  const {
    title = Title,
    bgColor = 'default', textColor = 'inherit',
    dense = false, elevation = 0,
    position = 'static',
  } = props;
  const [anchorCart, setAnchorCart] = useState(null);
  const { mode, prefersDarkMode } = useColorMode();
  const [sidebarOpen, setSidebarOpen] = useSidebarState();
  const { items } = useShoppingCart();
  const currentUser = getUserToken();
  const isAuthenticated = Boolean(get(StorageKeys.IsAuthenticated));
  const isAdmin = Boolean(currentUser?.root);
  const openCart = Boolean(anchorCart);
  const isAdminRoute = window.location.pathname.startsWith(Routes.admin.dashboard);

  const handleOpenCart = (event: any) => setAnchorCart(event.currentTarget);
  const handleCloseCart = () => setAnchorCart(null);

  const handleToggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const handleOpenAdmin = () => window.location.href = Routes.admin.dashboard;

  return (
    <AppBar
      position={position}
      color={bgColor}
      elevation={elevation}
      sx={{
        backgroundColor: bgColor,
        //borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Toolbar variant={dense ? "dense" : "regular"}>
        {isAuthenticated && isAdmin && isAdminRoute && (
          <IconButton
            edge="start"
            //size="small"
            onClick={() => handleToggleSidebar()}
            sx={{marginRight: 1, color: 'inherit'}}
          >
            <MenuIcon />
          </IconButton>
        )}
        <AppBrand
          title={title}
          textColor={textColor}
        />
        {NavItems.map((item, index) => (
          <Tooltip
            arrow
            key={index}
            title={item.text} // TODO: Tooltip
          >
            <a href={item.href} style={{textDecoration: 'none', color: 'inherit'}}>
              <Button
                style={{
                  textDecoration: 'none',
                  color: item.href === window.location.pathname
                    // Active nav items
                    ? mode === 'system'
                      ? prefersDarkMode ? ActiveMenuItemColor : 'lightgrey'
                      : mode === 'dark' ? ActiveMenuItemColor : 'white'
                    // Not active nav items
                    : 'lightgrey',
                }}
              >
                {item.text}
              </Button>
            </a>
          </Tooltip>
        ))}
        <Box sx={{flexGrow: 1}} />
        <NavbarHeaderRight
          cartItemsCount={items.length}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          onOpenCart={handleOpenCart}
          onOpenAdmin={handleOpenAdmin}
        />
      </Toolbar>
      <ShoppingCartPopover
        open={openCart}
        anchorEl={anchorCart}
        onClose={handleCloseCart}
      />
    </AppBar>
  );
};