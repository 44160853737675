import React, { CSSProperties, useMemo } from 'react';
import { useGetList } from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';
import { startOfDay, subDays } from 'date-fns';

import {
  MonthlyRevenue,
  NbNewOrders,
  NewCustomers,
  OrderChart,
  PendingOrders,
  Welcome,
} from '.';
import { OrderModel } from '../../../types';

interface OrderStats {
  revenue: number;
  nbNewOrders: number;
  pendingOrders: OrderModel[];
};

interface State {
  nbNewOrders?: number;
  pendingOrders?: OrderModel[];
  recentOrders?: OrderModel[];
  revenue?: string;
};

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

export const Spacer = () => <span style={{ width: '1em' }} />;
export const VerticalSpacer = () => <span style={{ height: '1em' }} />;

export const Dashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);

  const { data: orders } = useGetList<any>('orders', {
    filter: { createdAt: aMonthAgo.getTime() },
    sort: { field: 'createdAt', order: 'DESC' },
    pagination: { page: 1, perPage: 50 },
  });

  const aggregation = useMemo<State>(() => {
    if (!orders) {
      return {};
    }

    const aggregations = orders
      ?.filter(order => order.status !== 'canceled')
      ?.reduce(
        (stats: OrderStats, order: OrderModel) => {
          stats.revenue += parseFloat(order.amount?.toString());
          if (order.status !== 'canceled') {
            stats.nbNewOrders++;
          }
          if (order.status === 'pending') { // TODO: paid
            stats.pendingOrders.push(order);
          }
          return stats;
        },
        {
          revenue: 0,
          nbNewOrders: 0,
          pendingOrders: [],
        }
      );

    return {
      recentOrders: orders,
      revenue: (aggregations?.revenue ?? 0).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      nbNewOrders: aggregations?.nbNewOrders ?? 0,
      pendingOrders: aggregations?.pendingOrders ?? [],
    };
  }, [orders]);

  const { nbNewOrders, pendingOrders, revenue, recentOrders } = aggregation;

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <Welcome />
        <MonthlyRevenue value={revenue} />
        <VerticalSpacer />
        <NbNewOrders value={nbNewOrders} />
        <VerticalSpacer />
        <PendingOrders orders={pendingOrders} />
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>
        <Welcome />
      </div>
      <div style={styles.flex}>
        <MonthlyRevenue value={revenue} />
        <Spacer />
        <NbNewOrders value={nbNewOrders} />
      </div>
      <div style={styles.singleCol}>
        <OrderChart orders={recentOrders} />
      </div>
      <div style={styles.singleCol}>
        <PendingOrders orders={pendingOrders} />
      </div>
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <MonthlyRevenue value={revenue} />
            <Spacer />
            <NbNewOrders value={nbNewOrders} />
          </div>
          <div style={styles.singleCol}>
            <OrderChart orders={recentOrders} />
          </div>
          <div style={styles.singleCol}>
            <PendingOrders orders={pendingOrders} />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>
            <Spacer />
            <NewCustomers />
          </div>
        </div>
      </div>
    </>
  );
};