import React, { useState } from 'react';
import { IconButton, Input, InputAdornment } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';

interface QuantitySelectorProps {
  onQuantityChange: (quantity: number) => void;
};

export const QuantitySelector = (props: QuantitySelectorProps) => {
  const { onQuantityChange } = props;
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (newQuantity: number) => {
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  return (
    <Input
      type="number"
      value={quantity}
      onChange={(e) => handleQuantityChange(parseInt(e.target.value, 10))}
      startAdornment={
        <InputAdornment position="start">
          <IconButton onClick={() => handleQuantityChange(quantity - 1)}>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={() => handleQuantityChange(quantity + 1)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};