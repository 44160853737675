import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';

import { ProductGridItem, ProductListItem } from '../components';
import { ProductCategories } from '../consts';
import { useShoppingCart } from '../hooks';
import { ProductService } from '../services';
import { ProductModel } from '../types';

interface FilterOptions {
  category?: string;
  price?: {
    min?: number;
    max?: number;
  };
};

export const ProductsPage = () => {
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [filters, setFilters] = useState<FilterOptions>({});
  const [view, setView] = useState<'grid' | 'list'>('grid');
  const { addToCart } = useShoppingCart();

  const handleCategoryChange = (event: any) => {
    const value = event.target.value as string;
    setFilters((prevFilters) => ({ ...prevFilters, category: value }));
    // Apply the filters to products
    setProducts(products);
  };

  const handleToggleView = (viewType: 'grid' | 'list') => setView(viewType);

  const filteredProducts = applyFilters(products, filters);

  useEffect(() => {
    ProductService.getProducts().then((response: any) => {
      if (response.status !== 'ok') {
        // TODO: Error
        console.error('error:', response?.error);
        return;
      }
      setProducts(response.data);
    });
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom textAlign="center">
        Products
      </Typography>

      <Grid container>
        <Grid item xs={8}>
          <FormControl fullWidth sx={{mb: 3}} size="small">
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={filters.category || 'All'}
              label="Category"
              onChange={handleCategoryChange}
            >
              {ProductCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} style={{textAlign: 'right'}}>
          <ButtonGroup>
            <Button onClick={() => handleToggleView('grid')} color={view === 'grid' ? 'primary' : 'inherit'}>
              <ViewModuleIcon />
            </Button>
            <Button onClick={() => handleToggleView('list')} color={view === 'list' ? 'primary' : 'inherit'}>
              <ViewListIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      
      {/* Product list/grid */}
      {products.length === 0 ? (
        <Typography variant="h6" align="center">
          No Products Found
        </Typography>
      ) : (
        view === 'grid' ? (
          <Grid container spacing={2} justifyContent="space-evenly">
            {filteredProducts.map((product: ProductModel, index: number) => (
              <ProductGridItem
                key={index}
                product={product}
                onAddToCart={addToCart}
              />
            ))}
          </Grid>
        ) : (
          <List>
            {filteredProducts.map((product: ProductModel, index: number) => (
              <ProductListItem
                key={index}
                product={product}
                onAddToCart={addToCart}
              />
            ))}
          </List>
        )
      )}
    </Container>
  );
};

const applyFilters = (products: ProductModel[], filters: FilterOptions): ProductModel[] => {
  // Logic to filter products based on the filters
  let filtered = products;
  if (filters.category && filters.category !== 'All') {
    filtered = filtered.filter((product) => product.category === filters.category);
  }
  
  // Apply other filters like price, etc.
  
  return filtered;
};