import { Card, CardMedia } from '@mui/material';
import { useRecordContext } from 'react-admin';

import { ProductCategoryImages } from '../../../consts';
import { ProductModel } from '../../../types';

export const Poster = () => {
  const record = useRecordContext<ProductModel>();
  if (!record) {
    return null;
  }

  return (
    <Card sx={{ display: 'inline-block' }}>
      <CardMedia
        component="img"
        image={ProductCategoryImages[record.category]}
        alt=""
        sx={{ maxWidth: '42em', maxHeight: '15em' }}
      />
    </Card>
  );
};